<template>
    <q-card style="width: 600px; max-width: 60vw;">
        <q-bar class="bg-primary text-white">
            <q-icon name="fas fa-keyboard" />
            <div>
                选择套餐项目
                <small class="q-ml-sm text-size-sm">
                    <!-- {{ PdmBodycheckTaocanEntity.PdmBodycheckTaocanName }}({{
                              PdmBodycheckTaocanEntity.PdmBodycheckTaocanID
                          }}) -->
                </small>
            </div>

            <q-space />

            <q-btn dense flat icon="close" v-close-popup>
                <q-tooltip>关闭</q-tooltip>
            </q-btn>
        </q-bar>

        <q-card-section class="q-pa-sm">
            <div class="row q-gutter-sm q-mb-sm">
                <q-input outlined class="col-4" dense v-model="FilterString" label="项目名称" color="primary" />
                <q-btn color="primary" icon="search" @click="onLoadData" :loading="PdmMaItemListLoading"
                    style="height: 38px;">
                    查询
                </q-btn>
                <q-space />
            </div>
            <vxe-grid ref="xGrid" v-bind="gridOptions" :sort-config="{ multiple: true }" :custom-config="{ storage: true }"
                id="custom-config" :export-config="gridOptions.tableExport" :height="500" :loading="PdmMaItemListLoading"
                highlight-current-row size="mini" @checkbox-change="checkboxChangeEvent"
                @checkbox-all="checkboxChangeEvent">
                <template #toolbar_buttons id="custom-config"> </template>
            </vxe-grid>
        </q-card-section>
        <q-card-actions fixed align="right" class="bg-white text-teal">
            <q-btn flat label="取消" v-close-popup />
            <q-btn flat label="确定" @click="onOK" />
        </q-card-actions>
    </q-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
export default {
    components: {},
    computed: {
        ...mapState("PdmMaItem", [
            "PdmMaItemList",
            "PdmMaItemListLoading",
            "PdmMaItemEntity",
            "PdmMaItemShowDlg",
        ]),
        ...mapState("PdmMaPackItem", [
            "PdmMaPackItemList",
            "PdmMaPackItemListLoading",
            "PdmMaPackItemEntity",
            "PdmMaPackItemShowDlg",
        ]),
        ...mapState("PdmMaPack2", ["PdmMaPackEntity"]),

        PdmMaItemshowDlg: {
            get() {
                return this.PdmMaItemShowDlg;
            },
            set(val) {
                console.log(val);
                this.$store.commit("PdmMaItem/setPdmMaItemShowDlg", val);
            },
        },
        
    },
    data() {
        return {
            FilterString: "",
            gridCheckboxList: [],
            tablePage: {
                currentPage: 1,
                pageSize: 20,
                totalResult: 0,
                pageSizes: [15, 20, 50, 100, 5000],
            },
            gridOptions: {
                border: true,
                resizable: true,
                showOverflow: true,
                highlightHoverRow: true,
                align: "left",
                toolbarConfig: {
                    perfect: true,
                    print: true,
                    zoom: true,
                    custom: true,
                    export: true,
                    slots: {
                        // 自定义工具栏模板
                        buttons: "toolbar_buttons",
                    },
                },
                //点击编辑必须配置
                // editConfig: {
                //   trigger: "click",
                //   mode: "cell",
                //   activeMethod: () => {
                //     if (this.lockData) return false;
                //     return true;
                //   },
                // },
                tableExport: {},
                columns: [
                    { type: "checkbox", width: 50, align: "left" },
                    // {
                    //   field: "HospName",
                    //   title: "机构名称",
                    //   sortable: true,
                    //   align: "left",
                    //   width: 160,
                    // },
                    {
                        field: "ItemCode",
                        title: "项目编码",
                        sortable: true,
                        align: "left",
                        width: 100,
                    },
                    {
                        field: "ItemName",
                        title: "项目名称",
                        sortable: true,
                        align: "left",
                        width: 200,
                    },
                ],
                data: null,
            },
        };
    },
    mounted() {
        this.onLoadData();
    },
    methods: {
        ...mapActions("PdmMaItem", [
            "actGetPdmMaItemListByPage",
            "actCreatePdmMaItem",
            "actEditPdmMaItemData",
            "actEditPdmMaItem",
            "actDeletePdmMaItem",
            "actGetPdmMaItemList"
        ]),
        ...mapActions("PdmMaPackItem",[
            "actUpdatePdmMaPackItem"
        ]),
        onLoadData() {
            this.actGetPdmMaItemList({
                HospID: this.$store.getters["appUser/hospID"],
                CUser: this.$store.getters["appUser/userID"],
                FilterString: this.FilterString,
                PackID: this.PdmMaPackEntity.PackID,
            })
                .then((res) => {
                    this.gridOptions.data = res.Data;
                    this.tablePage.totalResult = res.Count;
                })
                .finally(() => {
                    this.$store.commit("PdmMaItem/setPdmMaItemListLoading",false);
                    //this.PdmMaItemListLoading = false;
                });
        },
        onOK() {
            console.log(`output->this.PdmMaPackEntity.Items`,this.PdmMaPackEntity.Items)
            console.log(`output->this.gridCheckboxList`,this.gridCheckboxList)
            if (typeof this.PdmMaPackEntity.Items === 'undefined'){
                this.PdmMaPackEntity.Items=this.gridCheckboxList
            }else{
                this.PdmMaPackEntity.Items.push(...this.gridCheckboxList)
            }
            console.log(`output->`,this.PdmMaPackEntity.Items)
            this.$store.commit('PdmMaItem/setPdmMaItemShowDlg', false);
        },
        //onExportData:{},
        //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
        footerMethod({ columns, data }) {
            var list = ["PRIC"]; //需要汇总的字段
            const footerData = [
                columns.map((column, columnIndex) => {
                    if (columnIndex === 0) {
                        return "合计";
                    }
                    if (list.includes(column.property)) {
                        return XEUtils.sum(data, column.property);
                    }
                    return null;
                }),
            ];
            return footerData;
        },
        checkboxChangeEvent() {
            var grid = this.$refs.xGrid;
            var gridCheckbox = grid.getCheckboxRecords();

            this.gridCheckboxList = gridCheckbox;

            console.log("gridCheckboxList==>", this.gridCheckboxList);
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage;
            this.tablePage.pageSize = pageSize;
            this.onLoadData();
        },
    },
};
</script>
  