<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input outlined class="col-2" dense v-model="FilterString" label="名称" color="primary" />
        <q-btn color="primary" icon="search" @click="onLoadData" :loading="PdmMaPackListLoading" style="height: 38px;">
          查询
        </q-btn>
        <q-space />
       
        <q-btn color="primary" style="height: 38px" icon="note_add" :loading="PdmMaPackListLoading"
          @click="onAdd">新增</q-btn>
      </div>
      <vxe-grid v-bind="gridOptions" :data="PdmMaPackList" :sort-config="{ multiple: true }" :custom-config="{ storage: true }"
        id="custom-config" :export-config="gridOptions.tableExport" :height="$store.getters.maxPageHeight - 140"
        :loading="PdmMaPackListLoading" highlight-current-row size="mini">

        <template #IsOffName="{ row }">
          <q-badge :color="row.IsOff == 0 ? 'positive' : 'negative'">
            {{ row.IsOffName }}
          </q-badge>
        </template>

        <template #operate="{ row }">
          <vxe-button flat color="primary" icon="fas  fa-edit" @click="onEdit(row)">编辑</vxe-button>
          <vxe-button flat color="danger" status="danger" icon="fas fa-trash" @click="onDelete(row)">删除</vxe-button>
          <vxe-button flat :status="row.IsOff == 0 ? 'danger' : 'primary'" color="negative" icon="fas fa-trash"
            @click="onIsOff(row)">{{ row.IsOff == 0 ? "下架" : "上架" }}</vxe-button>
        </template>
      </vxe-grid>
      <vxe-pager perfect size="mini" :page-sizes="tablePage.pageSizes" v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize" :total="tablePage.totalResult" :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]" @page-change="handlePageChange">
      </vxe-pager>
    </q-card-section>
  </q-card>

  <q-dialog v-model="pdmMaPackshowDlg" persistent>
    <PackEdit />
    <!-- <PackEdit @reloadData="onLoadData" /> -->
  </q-dialog>
</template>

<script>
import { mapActions, mapState,mapMutations  } from "vuex";
//import XEUtils from "xe-utils";
import PackEdit from "./packEdit.vue";
export default {
  components: {
    PackEdit,
  },
  computed: {
    ...mapState("PdmMaPack2", [
      "PdmMaPackShowDlg",
      "PdmMaPackEntity",
      "PdmMaPackList",
      "PdmMaPackListLoading"
    ]),
    ...mapState("PdmMaType",[
      "PdmMaTypeList"
    ]),

    pdmMaPackshowDlg: {
      get() {
        return this.PdmMaPackShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaPack2/setPdmMaPackShowDlg", val);
      },
    }
  },
  data() {
    return {
      FilterString: "",
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      typeListData: [],
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
          },
        },

        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "PackCode",
            title: "套餐编码",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "PackName",
            title: "套餐名称",
            sortable: true,
            align: "left",
            width: 120,
          },
          {
            
            field: "TypeName",
            title: "所属分类",
            sortable: true,
            align: "left",
            width: 90,
          },
          {
            field: "Titles",
            title: "副标题",
            sortable: true,
            align: "left",
            width: 120,
          },
          {
            field: "IsOffName",
            title: "上下架情况",
            slots:{default:"IsOffName"},
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "PackAttrName",
            title: "套餐属性",
            sortable: true,
            align: "left",
            width: 90,
          },
          {
            field: "Amount",
            title: "套餐原价",
            sortable: true,
            align: "right",
            width: 90,
            formatter({ cellValue }) {
              return parseFloat(cellValue).toFixed(2);
            },
          },
          {
            field: "SaleAmount",
            title: "折后金额",
            sortable: true,
            align: "right",
            width: 90,
            formatter({ cellValue }) {
              return parseFloat(cellValue).toFixed(2);
            },
          },
          {
            field: "IsHotName",
            title: "是否为热门",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "Summary",
            title: "简介介绍/总结",
            sortable: true,
            align: "left",
            width: 200,
          },    
          {
            title: "操作",
            width: 250,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
        ],
        data: this.PdmMaPackList,
        // data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();

  },
  methods: {
    ...mapActions("PdmMaPack2", [
      "actCreatePdmMaPack",
      "actGetPdmMaPackEntity",
      "actGetPdmMaPackList",
      "actGetPdmMaPackListByPage",
      "actUpdatePdmMaPack",
      "actEditPdmMaPackData",
      "actEditPdmMaPack",
      "actDeletePdmMaPack",
      "actSetPDM_MA_PackIsOff"
    ]),
    ...mapActions("PdmMaType", [
      "actGetPdmMaTypeList"
    ]),
    ...mapMutations('PdmMaType', ['setPdmMaTypeList']),
    onLoadData() {
      this.actGetPdmMaPackListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          // this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit("PdmMaPack2/setPdmMaPackListLoading", false);
          //this.PdmMaPackListLoading = false;
        });
      this.actGetPdmMaTypeList({})
        .then((res) => {
          if (res.Code == 0) {
            this.typeListData = res.Data;
            this.setPdmMaTypeList( res.Data );
          }
        })
        .catch((res) => {
          this.msg.error(res.Message || res);
        });
    },
    onAdd() {
      this.actCreatePdmMaPack({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      });
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmMaPackData(obj);
    },
    onDelete(e) {
      this.actDeletePdmMaPack({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        PackID: e.PackID,
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },

    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
    onIsOff(e){
      var obj = {};
      Object.assign(obj, e);
      this.actSetPDM_MA_PackIsOff({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: obj,
      }).then((res) => {
        if (res.Code == 0) {
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
