<template>
  <q-card style="width: 750px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          {{ PdmMaPackEntity.PackName != "" ? "编辑套餐" : "新增套餐" }}
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section>
      <q-card-section>
        <div class="row q-gutter-sm q-mb-sm">
          <div class="col" style="display: flex; flex-direction: row;">
            <div style="flex: 1;">
              <q-input
                dense
                outlined
                v-model="PdmMaPackEntity.PackCode"
                label="套餐编码"
              />
            </div>
          </div>
        </div>

        <div class="row q-gutter-sm q-mb-sm">
          <div class="col" style="display: flex; flex-direction: row;">
            <div style="flex: 1;">
              <q-input
                dense
                outlined
                v-model="PdmMaPackEntity.Titles"
                label="职称"
              />
            </div>
          </div>
        </div>

        <div class="row q-gutter-sm q-mb-sm">
          <div class="col" style="display: flex; flex-direction: row;">
            <div style="flex: 1;">
              <q-input
                dense
                outlined
                v-model="PdmMaPackEntity.Summary"
                label="简介"
              />
            </div>
          </div>
        </div>

        <div class="row q-gutter-sm q-mb-sm">
          <div class="col" style="display: flex; flex-direction: row;">
            <div style="flex: 1;">
              <q-input
                dense
                outlined
                v-model="PdmMaPackEntity.PackName"
                label="套餐名称"
              />
            </div>
          </div>
        </div>

        <div class="row q-gutter-sm q-mb-sm">
          <div class="col" style="display: flex; flex-direction: row;">
            <q-input
              dense
              outlined
              v-model="PdmMaPackEntity.Amount"
              label="套餐原价"
              type="number"
              style="flex: 1; margin-right: 10px;"
            ></q-input>
            <q-input
              dense
              outlined
              v-model="PdmMaPackEntity.SaleAmount"
              label="套餐打折后价格"
              type="number"
              style="flex: 1; margin-right: 10px;"
            ></q-input>
            <q-input
              dense
              outlined
              type="number"
              v-model="PdmMaPackEntity.PackAppted"
              label="已约底数"
              style="flex: 1;"
            ></q-input>
          </div>
        </div>
        <div class="row q-gutter-sm q-mb-sm">
          <div class="col" style="display: flex; flex-direction: row;">
            <q-select
              style="flex: 1; margin-right: 10px;"
              dense
              outlined
              label="分类选择"
              v-model="PdmMaPackEntity.TypeID"
              :options="PdmMaTypeList"
              option-value="TypeID"
              option-label="TypeName"
              emit-value
              map-options
            />
            <q-input
              style="flex: 1; "
              dense
              outlined
              type="number"
              v-model="PdmMaPackEntity.PackSort"
              label="排序"
            />
          </div>
        </div>
        <div class="row q-mb-sm">
          <div class="col-3" >
            <q-btn-toggle
              v-model="PdmMaPackEntity.IsHot"
              push
              glossy
              toggle-color="primary"
              :options="[
                { label: '非热门', value: 0 },
                { label: '是热门', value: 1 },
              ]"
            />
          </div>

          <div class="col-3" style="width: 140px;">
            <q-btn-toggle
              v-model="PdmMaPackEntity.IsOff"
              push
              glossy
              toggle-color="primary"
              :options="[
                { label: '上架', value: 0 },
                { label: '下架', value: 1 },
              ]"
            />
          </div>
          <div class="col-3">
            <q-btn-toggle
              v-model="PdmMaPackEntity.PackAttr"
              push
              glossy
              toggle-color="primary"
              :options="[
                { label: '套餐', value: 0 },
                { label: '人', value: 1 },
              ]"
            />
          </div>
        </div>
        <q-card class="col-12">
          <div class="row q-my-xs">
            <div class="col-3">
              <div class="text-subtitle1 q-pl-sm">套餐项目：</div>
            </div>
            <div class="col-9 q-pr-sm">
              <q-btn
                style="float:right"
                color="primary"
                size="11px"
                label="添加"
                @click="AddItem"
              />
            </div>
          </div>
          <q-separator />
          <q-chip
            v-for="item in PdmMaPackEntity.Items"
            :key="item.PackItemID"
            removable
            @remove="onDelPackItem(item)"
            color="secondary"
            text-color="white"
          >
            {{ item.ItemName }}
          </q-chip>
        </q-card>
      </q-card-section>
      <q-card-section
        style="margin-top: -20px; margin-bottom: -20px;"
        class="col-12"
      >
        <div class="text-grey-7">体检流程:</div>
        <div>
          <q-editor
            dense
            outlined
            v-model="PdmMaPackEntity.Remark"
            :definitions="{
              bold: { label: 'Bold', icon: null, tip: '体检流程' },
            }"
          />
        </div>
      </q-card-section>
      <q-card-section
        style="margin-top: -20px; margin-bottom: -20px;"
        class="col-12"
      >
        <div class="text-grey-7">体检须知:</div>
        <div>
          <q-editor
            dense
            outlined
            v-model="PdmMaPackEntity.Notice"
            :definitions="{
              bold: { label: 'Bold', icon: null, tip: '体检须知' },
            }"
          />
        </div>
      </q-card-section>

      <!-- 添加图片 -->
      <q-card-section>
        <div class="col-12 text-grey-7 q-gutter-sm q-mb-sm">
          <div class="row">
            <div class="col-6" style="flex: 1; margin-right: 10px;">
              <q-card bordered>
                <div class="q-my-xs">
                  <div class="row">
                    <div class="col-9">
                      <div class="text-subtitle1 q-pl-sm">
                        套餐列表图片(164x164)：
                      </div>
                    </div>
                    <div class="col-3 q-pr-sm">
                      <q-btn
                        style="float:right"
                        color="primary"
                        size="11px"
                        label="上传"
                        @click="onListFiles"
                      />
                    </div>
                  </div>
                </div>
                <q-separator />
                <q-img
                  v-if="PdmMaPackEntity.PackListImages"
                  :src="
                    apiUrl +
                      'UploadImage/MAPack/' +
                      PdmMaPackEntity.HospID +
                      '/' +
                      PdmMaPackEntity.PackListImages
                  "
                  spinner-color="white"
                  style="height: 200px; max-width: 370px"
                />
              </q-card>
            </div>
            <div class="col-6" style="flex: 1;">
              <q-card bordered>
                <div class="q-my-xs">
                  <div class="row">
                    <div class="col-9">
                      <div class="text-subtitle1 q-pl-sm">
                        套餐详情图片(750x400)：
                      </div>
                    </div>
                    <div class="col-3 q-pr-sm">
                      <q-btn
                        style="float:right"
                        color="primary"
                        size="11px"
                        label="上传"
                        @click="onDetailsFiles"
                      />
                    </div>
                  </div>
                </div>
                <q-separator />
                <q-img
                  v-if="PdmMaPackEntity.PackDetailsImages"
                  :src="
                    apiUrl +
                      'UploadImage/MAPack/' +
                      PdmMaPackEntity.HospID +
                      '/' +
                      PdmMaPackEntity.PackDetailsImages
                  "
                  spinner-color="white"
                  style="height: 200px; max-width: 370px"
                />
              </q-card>
            </div>
          </div>
          <!-- 上传图片控件-列表图片用的 -->
          <q-uploader
            label="Auto Uploader"
            ref="ListFiles"
            v-show="false"
            auto-upload
            :url="apiUrl + 'api/PDM_MA_Pack2/UploadImage_List'"
            accept=".jpg, image/*"
            @uploaded="onUploaded_List"
            multiple
            :headers="[{ name: 'HospID', value: PdmMaPackEntity.HospID }]"
          />

          <!-- 上传图片控件-详情图片用的 -->
          <q-uploader
            label="Auto Uploader"
            ref="DetailsFiles"
            v-show="false"
            auto-upload
            :url="apiUrl + 'api/PDM_MA_Pack2/UploadImage_Details'"
            accept=".jpg, image/*"
            @uploaded="onUploaded_Details"
            multiple
            :headers="[{ name: 'HospID', value: PdmMaPackEntity.HospID }]"
          />
        </div>
      </q-card-section>
    </q-card-section>
    <div class="footer">
      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="取消" v-close-popup />
        <q-btn flat label="确定" @click="onOK" />
      </q-card-actions>
    </div>
  </q-card>

  <!-- <q-dialog v-show="flag" persistent> -->
  <q-dialog v-model="pdmMaItemShowDlg" persistent>
    <!-- <PackItem @childMethodCalled="onLoadMaPackItem" /> -->
    <PackItem />
  </q-dialog>
</template>
<style>
.footer {
  position: fixed;
  bottom: 0;
  width: 750px;
  max-width: 60vw;
  height: auto;
  /* 页脚高度 */
  background: white;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import PackItem from "./packItem.vue";
import * as PdmMaPackItem from "@/api/pdm/ma/maPackItem";
export default {
  components: {
    PackItem,
  },
  props: {},
  computed: {
    ...mapState("PdmMaPack2", [
      "PdmMaPackShowDlg",
      "PdmMaPackEntity",
      "PdmMaPackList",
      "PdmMaPackListLoading",
    ]),
    ...mapState("PdmMaItem", [
      "PdmMaItemList",
      "PdmMaItemListLoading",
      "PdmMaItemEntity",
      "PdmMaItemShowDlg",
    ]),
    ...mapState("PdmMaType", ["PdmMaTypeList"]),
    pdmMaItemShowDlg: {
      get() {
        return this.PdmMaItemShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaItem/setPdmMaItemShowDlg", val);
      },
    },
  },
  data() {
    return {
      selectedValue: "",
      apiUrl: null,
    };
  },
  created() {
    this.apiUrl = this.$store.getters["appUser/ImagesUrl"];
  },
  mounted() {
    this.PdmMaPackEntity.HOSPID = this.hospID;
  },
  methods: {
    ...mapActions("PdmMaPack2", ["actUpdatePdmMaPack", "actGetPdmMaPackList"]),
    onOK() {
      console.log(`output->`, this.PdmMaPackEntity);
      this.actUpdatePdmMaPack({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        PackID: this.PdmMaPackEntity.PackID,
        Info: this.PdmMaPackEntity,
      }).then((res) => {
        if (res.Code == 0) {
          setTimeout(() => {
            this.$emit("reloadData");
          }, 200);
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onDetailsFiles() {
      this.$refs.DetailsFiles.pickFiles();
      this.$refs.DetailsFiles.reset(); //将上传器重置为默认值；清空队列，中止当前上传
    },
    onListFiles() {
      this.$refs.ListFiles.pickFiles();
      this.$refs.ListFiles.reset(); //将上传器重置为默认值；清空队列，中止当前上传
    },
    onUploaded_List(info) {
      var ReturnSign = JSON.parse(info.xhr.responseText).ReturnSign;
      this.PdmMaPackEntity.PackListImages = ReturnSign.filesname;
    },
    onUploaded_Details(info) {
      var ReturnSign = JSON.parse(info.xhr.responseText).ReturnSign;
      this.PdmMaPackEntity.PackDetailsImages = ReturnSign.filesname;
    },
    onDelPackItem(item) {
      PdmMaPackItem.DeletePDM_MA_PackItemEntity({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: item,
      }).then((res) => {
        if (res.Code == 0) {
          let indexToDelete = -1;

          for (let i = 0; i < this.PdmMaPackEntity.Items.length; i++) {
            if (this.PdmMaPackEntity.Items[i].PackItemID === item.PackItemID) {
              indexToDelete = i;
              break;
            }
          }
          // 如果找到了要删除的元素，则使用 splice 方法将其删除
          if (indexToDelete !== -1) {
            this.PdmMaPackEntity.Items.splice(indexToDelete, 1);
          }
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    // 添加套餐项目
    AddItem() {
      this.$store.commit("PdmMaItem/setPdmMaItemShowDlg", true);
      console.log(`output->PdmMaItemShowDlg`, this.PdmMaItemShowDlg);
    },
    onCancel() {
      this.$store.commit("PdmMaPack2/setPdmMaPackShowDlg", false);
    }, //任务：关闭窗口或按钮。 不应显示此按钮。 如果指定
  },
};
</script>
