/*------------------------------------------------
// File Name:PDM_MA_PackItem.js
// File Description:PDM_MA_PackItem API方法js接口
// Author:zxl
// Create Time:2023/06/06 15:39:47
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _maPackItem: "/PDM_MA_PackItem"
}

export default api


/**
 * 创建预约套餐对象
 * @param {*} parameter 
 * @returns 
 */
export function CreatePDM_MA_PackItemEntity(parameter) {
    return axios({
        url: api._maPackItem + "/CreatePDM_MA_PackItemEntity",
        method: 'post',
        data: parameter
    })
}

/**
 * 获取预约套餐列表
 * @param {*} parameter 
 * @returns 
 */
export function GetPDM_MA_PackItemList(parameter) {
    return axios({
        url: api._maPackItem + "/GetPDM_MA_PackItemList",
        method: 'post',
        data: parameter
    })
}

/**
 * 分页获取预约套餐列表
 * @param {*} parameter 
 * @returns 
 */
export function GetPDM_MA_PackItemListByPage(parameter) {
    return axios({
        url: api._maPackItem + "/GetPDM_MA_PackItemListByPage",
        method: 'post',
        data: parameter
    })
}

/**
 * 获取预约套餐对象
 * @param {*} parameter 
 * @returns 
 */
export function GetPDM_MA_PackItemEntity(parameter) {
    return axios({
        url: api._maPackItem + "/GetPDM_MA_PackItemEntity",
        method: 'post',
        data: parameter
    })
}


/**
 * 保存预约套餐对象
 * @param {*} parameter 
 * @returns 
 */
export function UpdatePDM_MA_PackItemEntity(parameter) {
    return axios({
        url: api._maPackItem+ "/UpdatePDM_MA_PackItemEntity",
        method: 'post',
        data: parameter
    })
}

/**
 * 删除预约套餐对象
 * @param {*} parameter 
 * @returns 
 */
export function DeletePDM_MA_PackItemEntity(parameter) {
    return axios({
        url: api._maPackItem + "/DeletePDM_MA_PackItemEntity",
        method: 'post',
        data: parameter
    })
}
/**
 * 获取上架套餐列表
 * @param {*} parameter 
 * @returns 
 */
export function GetIsOffPDM_MA_PackItemList(parameter) {
    return axios({
        url: api._maPackItem + "/GetIsOffPDM_MA_PackItemList",
        method: 'post',
        data: parameter
    })
}
